import React from "react"
import Layout from "../../../template/Layout"

/**
 * Material
 */
import Head from "../../../head"
import { SectionTitle } from "../../common/SectionTitle"
import { ContactContainer } from "../common/ContactContainer"
import { ContactDescription } from "../common/ContactDescription"
import { ContactRecruitForm } from "./ContactRecruitForm"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

export const RecruitForm: React.VFC<Props> = props => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="採用エントリーフォーム | ネクストライブ株式会社"
        description="ネクストライブ株式会社の採用エントリーフォームです。"
      />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="採用エントリー" step={1} />
        <ContactRecruitForm />
      </ContactContainer>
    </Layout>
  )
}
