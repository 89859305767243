import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

/**
 * Material
 */
import { ContactInputGroup } from "../common/ContactInputGroup"
import { ContactInputText } from "../common/ContactInputText"
import { ContactInputTextarea } from "../common/ContactInputTextarea"
import { ContactInputLink } from "../common/ContactInputLink"
import { ContactInputFile } from "../common/ContactInputFile"
import { AgreedPolicy } from "../common/AgreedPolicy"
import { recruitFormValues, useRecruitsFormContext } from "../../../utlis/FormValues"
import { validRequired, invalidRequired } from "../../../utlis/check"
import { contact } from "../../../data/contact"
import { ContactFormConfirmButton } from "../common/ContactFormConfirmButton"
import { ContactInputEmail } from "../common/ContactInputEmail"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
`

type FormData = recruitFormValues
type Errors = {
  name: boolean
  email: boolean
}

/**
 * Component
 */
export const ContactRecruitForm: React.VFC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resumeFiles: [],
    workFiles: [],
    webSites: [],
    point: "",
    other: "",
  })
  const [checked, setChecked] = useState(false)
  const [isErrors, setIsErrors] = useState<Errors>({
    name: false,
    email: false,
  })

  const updateFormData = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value })
    setIsErrors(_errors => ({ ..._errors, [name]: false }))
  }

  // バリデーション
  const validate = (values: FormData) => {
    setIsErrors(_errors => ({ ..._errors, name: invalidRequired(values.name) }))
    setIsErrors(_errors => ({ ..._errors, email: invalidRequired(values.email) }))
  }

  const isValid = (values: FormData) => {
    validate(values)
    return (
      validRequired(values.name) &&
      validRequired(values.email)
    )
  }

  function setBase64ToState(event, setFileElement: Function) {
    // キャンセルにて未選択にする
    setFileElement(null)

    if (event && event.target.files.length > 0) {
      const UNIT = 1 // MB
      const FILE_SIZE_LIMIT = 1024 * 1024 * UNIT
      const file = event.target.files[0]
      if (file.size > FILE_SIZE_LIMIT) {
        event.target.value = ""
        alert(`アップロードするファイルサイズは、${UNIT}MB以下にしてください。`)
        return
      }
      setFileElement(event.target)
    }
  }

  const setFileElement = (el, name, funcSet) => {
    if (el) {
      const e = document.querySelector(name)
      const p = e.parentNode
      // 空のinput fileを削除し、確認画面のinput fileをセット
      p.textContent = null
      p.append(el)

      funcSet(el)
    }
  }

  const { state, setState } = useRecruitsFormContext()

  //useEffect(() => {
  //  //setFileElement(
  //  //  state.resumeElement,
  //  //  `input[name="履歴書（添付）"]`,
  //  //  setResumeElement
  //  //)
  //  //setFileElement(
  //  //  state.workExperienceElement,
  //  //  `input[name="職務経歴書（添付）"]`,
  //  //  setWorkExperienceElement
  //  //)
  //  //setFileElement(
  //  //  state.portfolioElement,
  //  //  `input[name="ポートフォリオ（添付）"]`,
  //  //  setPortfolioElement
  //  //)
  //}, [])

  useEffect(() => {
    setFormData({ ...state })
  }, [])

  return (
    <Form
      onSubmit={event => {
        event.preventDefault()

        // エラーがある場合、画面遷移しない
        if (!isValid(formData)) return

        setState({
          ...formData
        })

        navigate("./confirm")
      }}
    >
      <FormNode>

        <ContactInputGroup
          label={contact.recruit.name.title}
          isRequired={contact.recruit.name.required}
          hasError={isErrors.name}>
          <ContactInputText
            value={formData.name}
            setter={(value) => updateFormData(value, "name")}
            isRequired={contact.recruit.name.required}
            placeholder={"例）大阪　太郎"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.email.title}
          isRequired={contact.recruit.email.required}
          hasError={isErrors.email}>
          <ContactInputEmail
            value={formData.email}
            setter={(value) => updateFormData(value, "email")}
            isRequired={contact.recruit.email.required}
            placeholder={"例）example@nextribe.co.jp"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.resumeFiles.title}
          isRequired={contact.recruit.resumeFiles.required}
          message={contact.recruit.resumeFiles.annotaion}
          >
          <ContactInputFile
            id={"resume-file"}
            files={formData.resumeFiles}
            setter={(value) => updateFormData(value, "resumeFiles")}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.workFiles.title}
          isRequired={contact.recruit.workFiles.required}
          message={contact.recruit.workFiles.annotaion}
          >
          <ContactInputFile
            id={"work-file"}
            files={formData.workFiles}
            setter={(value) => updateFormData(value, "workFiles")}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.webSites.title}
          isRequired={contact.recruit.webSites.required}>
          <ContactInputLink
            links={formData.webSites}
            setter={(value) => updateFormData(value, "webSites")}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.point.title}
          isRequired={contact.recruit.point.required}>
          <ContactInputTextarea
            value={formData.point}
            setter={(value) => updateFormData(value, "point")}
            isRequired={contact.recruit.point.required}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.recruit.other.title}
          isRequired={contact.recruit.other.required}>
          <ContactInputTextarea
            value={formData.other}
            setter={(value) => updateFormData(value, "other")}
            isRequired={contact.recruit.other.required}
          />
        </ContactInputGroup>

        <AgreedPolicy checked={checked} setter={setChecked} />

        <ContactFormConfirmButton isDisabled={!checked} />
      </FormNode>
    </Form>
  )
}
