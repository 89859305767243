import React, { useState } from "react"
import styled from "styled-components"

import { RESPONSIVE_STYLES } from "../../../AppConst"

import iconPlus from "../../../images/plus.svg"
import { CONTACT_INPUT_STYLE } from "./styles"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Button = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 320px;
  height: 72px;
  padding: 24px;
  box-sizing: border-box;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;

  background: #222222;
  border: solid 2px #FFFFFF;
  transition: .3s;
  cursor: pointer;
  &:hover {
    color: #222222;
    background: #FFFFFF;
    border: solid 2px #222222;
    &:before {
      background-color: #222222;
    }
  }
  &:before {
    content: '';
    position: absolute;
    mask-image: url(${iconPlus});
    top: 26px;
    right: 24px;
    width: 16px;
    height: 16px;
    margin: auto;
    background-color: #FFFFFF;
    transition: .3s;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: none;
    height: 56px;
    &:before {
      top: 18px;
      right: 32px;
      background-color: #222222;
    }
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 32px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px 16px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px;
  }
`

const Link = styled.input`
  ${CONTACT_INPUT_STYLE}
`

const TrashButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 16px;
    height: 16px;
  }
`

type Props = {
  links: Array<string>
  setter: Function
}

export const ContactInputLink: React.VFC<Props> = ({links, setter}) => {
  const [count, setCount] = useState(0);
  const add = () => {
    setCount(count + 1)
    setter([...links, ""]);
  }

  const updateLink = (e, i) => {
    links[i] = e.value;
    setter([...links]);
  }

  const deleteLink = (i) => {
    setCount(count + 1)
    const newLinks = links.filter((l, j) => i !== j);
    setter([...newLinks]);
  }

  return (
    <Wrapper>
      {
        links.length > 0 &&
        <LinksWrapper>
          {
            links.map((l, i) => {
              return (
                <LinkWrapper key={`${count}${i}`}>
                  <Link
                    defaultValue={l}
                    onChange={(e) => updateLink(e.target, i)}
                    onBlur={(e) => updateLink(e.target, i)}
                    placeholder={`テキストを入力してください`}
                  />
                  <TrashButton onClick={() => deleteLink(i)}>
                    <Icon alt={"ゴミ箱"} src={iconPlus} />
                  </TrashButton>
                </LinkWrapper>
              )
            })
          }
        </LinksWrapper>
      }
      <Button onClick={() => add()}>リンクを追加</Button>
    </Wrapper>
  )
}
