import React from "react"

/**
 * Material
 */
import { RecruitForm } from "../../../components/contact/recruit"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `採用エントリー`, path: `contact/recruit` },
]

export default function Home() {
  return (
    <RecruitForm breadcrumbsData={breadcrumbsData} />
  )
}
