import React, { useState } from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import iconDustBox from "../../../images/dust_box.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Input = styled.input`
  display: none;
`

const Button = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  background: #222222;
  border: 2px solid #FFFFFF;
  transition: .3s;
  cursor: pointer;
  &:hover {
    color: #222222;
    background: #FFFFFF;
    border: 2px solid #222222;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    height: 56px;
  }
`

const FilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 32px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px 16px;
  }
`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FileName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  word-break: break-word;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
  }
`

const TrashButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`

const Icon = styled.img`
`

const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
  }
`

type Props = {
  id: string
  files: Array<HTMLInputElement>
  setter: Function
  hasError?: boolean
}

export const ContactInputFile: React.VFC<Props> = ({id, files, setter, hasError}) => {
  const [count, setCount] = useState(0);
  const createId = (id, i) => `${id}-${i}`;

  const selectedFile = (id, e, files, setter) => {
    setCount(count + 1)

    if (files.length >= 2) {
      alert(`アップロードするファイルは、2つまでにしてください。`)
    }
    else if (e.target.files.length > 0) {
      const UNIT = 1 // MB
      const FILE_SIZE_LIMIT = 1024 * 1024 * UNIT
      const file = e.target.files[0]
      if (file.size > FILE_SIZE_LIMIT) {
        alert(`アップロードするファイルサイズは、${UNIT}MB以下にしてください。`)
      } else {
        // ファイルエレメントをコピーしてIDを設定
        const fileElement = e.target.cloneNode(true);
        fileElement.id = createId(id, files.length);
        setter([...files, fileElement]);
      }
    }

    // 再度ファイル選択できるように初期化
    e.target.value = null;
  }

  const deleteFile = (id, e) => {
    setCount(count + 1)
    const newFiles = files.filter((f) => f.id !== e.id);
    // ID振り直し
    newFiles.forEach((f, i) => {
      f.id = createId(id, i);
    })
    setter([...newFiles]);
  }

  return (
    <Wrapper>
      <Input
        id={id}
        type="file"
        onChange={(e) => selectedFile(id, e, files, setter)}
        data-is-error={!!hasError}
      />
      <Button htmlFor={id}>ファイルを選択</Button>
      {
        files.length === 0 && <Text>選択されていません</Text>
      }
      {
        files.length > 0 &&
        <FilesWrapper>
          {
            files.map((f, i) => {
              return (
                <FileWrapper key={`${count}${i}`}>
                  <FileName>{f.files[0].name}</FileName>
                  <TrashButton onClick={() => deleteFile(id, f)}>
                    <Icon alt="ゴミ箱" src={iconDustBox} />
                  </TrashButton>
                </FileWrapper>
              )
            })
          }
        </FilesWrapper>
      }
    </Wrapper>
  )
}
